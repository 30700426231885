import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
// import Footer from "../../../components/Footer/Footer";

import "./GrossIncome.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import { StepPreFooter } from "../../../components/Footer/StepPreFooter";

import GrossIncomeIcon from "../../../assets/images/step-icon/GrossIncome-icon.png";
import checkedIcon from "../../../assets/images/checked.png";

import { updateContact } from "../../../redux/apiCalls";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { GrossIncomeOptions } from "../../../utils/options";
import { nextStepDelayTime } from "../../../utils/const";
import { getPrevStepLink } from "utils/functions";

const GrossIncome = () => {
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [monthlyIncome, setMonthlyIncome] = useState(
    contactRedux?.incomeNetMonthly ?? contactRedux?.monthlyIncome ?? ""
  );
  const [loadingButton, setLoadingButton] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const currentLoc = useLocation();
  const prevLink = getPrevStepLink(currentLoc.pathname);

  // custom
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();
  const onSubmit = async (value) => {
    if (!monthlyIncome) {
      setError("monthlyIncome", {
        type: "error",
        message: "Please choose an answer"
      });
      return;
    }

    setLoadingButton(true);

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      step: 13,
      lead_id,
      incomeNetMonthly: monthlyIncome
    });

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/nextpaydate");
      }, nextStepDelayTime);
    }
  };

  return (
    <Box className="GrossIncome-page">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={12} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={GrossIncomeIcon}
                alt=""
              />
            </Box>
            <h2 className="title">Monthly Gross Income</h2>
            <h5>
              Your total income (please, include all sources) before taxes
            </h5>
            <h5>You are not required to disclose income that is alimony, child support, or separate maintenance unless you want this income to be used in consideration as a basis for your request and loan repayment. Further, you may increase any non-taxable income or benefits by 25%.</h5>

            <div className="form-control">
              <div className="list-btn-radio">
                {GrossIncomeOptions.map((option, index) => (
                  <button
                    key={index}
                    className={`btn-radio ${
                      monthlyIncome === option.value ? "active" : ""
                    }`}
                    onClick={() => {
                      setMonthlyIncome(option.value);
                      clearErrors("monthlyIncome");
                    }}
                  >
                    {option.label}
                    {monthlyIncome === option.value && (
                      <img className="checked-icon" src={checkedIcon} alt="" />
                    )}
                  </button>
                ))}
              </div>

              {errors?.monthlyIncome && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "10px",
                    display: "block",
                    textAlign: "center"
                  }}
                  className="error"
                >
                  {errors?.monthlyIncome?.message}
                </span>
              )}
            </div>

            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink={prevLink}
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box>
          </form>
          <StepPreFooter />
        </div>
      </Box>

      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default GrossIncome;
