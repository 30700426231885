import React, { useEffect } from "react";

import "./RedirectPage.scss";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import checkdraw from "../../../assets/images/checkdraw.PNG";

const RedirectPage = () => {
  const redirectURL = localStorage.getItem("redirectURL");
  useEffect(() => {
    setTimeout(() => {
      if (redirectURL) window.location.href = redirectURL;
    }, 2000);
  });

  return (
    <div className="redirect-upstart">
      <nav>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
      </nav>
      <div className="redirect-content">
        {/* <h1>We've found the program for you!</h1> */}
        {/* <h3 className="configuration">Congratulation!</h3> */}
        <h3 className="text">
          Based on the information you provided,
          <br /> there is a suitable program we found out to help you connect
          with the lender, please hold tight!
        </h3>

        <div className="logo-upstart">
          <img src={checkdraw} alt="" />
        </div>
        <div class="loading-text">
          <h3>
            REDIRECTING
            <span class="dot-one"> .</span>
            <span class="dot-two"> .</span>
            <span class="dot-three"> .</span>
          </h3>
        </div>
      </div>
      <footer>
        <p>© 2024 Quicklyfincal.com All rights reserved.</p>
      </footer>
    </div>
  );
};

export default RedirectPage;
