import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { partners } from "../../../utils/const";
import "./Partners.scss";

const Partners = () => {

  
  return (
    <div className="credit-authorization-agreement">
      <Navbar />
      <div className="container-privacy">
        <div className="box-privacy">
          <h2 className="title">Ours partners</h2>
          <p class="partner-list">
            { partners.join(', ')}.
          </p>
        </div>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default Partners;
