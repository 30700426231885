import React, { useRef, useEffect } from "react";
import "./MarketingPartners.scss";
import close from "../../../assets/images/close1.png";
import { partners } from "../../../utils/const";

export const MarketingPartners = ({
  opentMarketingPartners,
  setOpentMarketingPartners
}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpentMarketingPartners(false);
      }
    };

    if (opentMarketingPartners) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [opentMarketingPartners, setOpentMarketingPartners]);

  return (
    <div
      className={`marketing-partners ${opentMarketingPartners ? "open" : ""}`}
    >
      <div className="box-popup">
        <div className="container">
          <div className="content-popup" ref={popupRef}>
            <h2 className="title">Marketing Partners</h2>
            <p className="text">
              { partners.join(', ')}.
            </p>
          </div>
          <button
            className="close"
            onClick={() => setOpentMarketingPartners(!opentMarketingPartners)}
          >
            <img src={close} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};
