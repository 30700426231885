import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api`;
const INHOUSE_BASE_URL = `${process.env.REACT_APP_INHOUSE_API_BASE_URL}/v1/fe`;

const TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYjNhNWNkN2JhMmVmODU0ZmEzMjE4ZiIsImlzQWRtaW4iOnRydWUsImlhdCI6MTY3NjQ2NjQ5MywiZXhwIjoxNjc2NzI1NjkzfQ.sjsPVkrL-gRbhHglFxyhAnS5eubM7rq2xWqQruJS2Ec";

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const inHousePublicRequest = axios.create({
  baseURL: INHOUSE_BASE_URL,
});

export const inHousePersonalLoanRequest = axios.create({
  baseURL: INHOUSE_BASE_URL + "/personal_loan/lead",
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  header: { token: `Bearer ${TOKEN}` },
});

export const zipCodeRequest = axios.create({
  baseURL: INHOUSE_BASE_URL + '/personal_loan/zipcode',
});