import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
// import Footer from "../../../components/Footer/Footer";

import "./BankAccount.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";

import { StepPreFooter } from "../../../components/Footer/StepPreFooter";
import { useLocation } from "react-router-dom";
import { getPrevStepLink } from "../../../utils/functions";

import ABAIcon from "../../../assets/images/step-icon/aba-icon.png";

import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm, Controller } from "react-hook-form";
import { Box, FormHelperText, TextField } from "@mui/material";
import { FormControl } from "@mui/material";
import {
  formControlCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles
} from "./style";
// CUSTOM STYLE
import { trimWhiteSpaceObjValue } from "../../../utils/functions";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { nextStepDelayTime } from "../../../utils/const";

const BankAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentLoc = useLocation();
  const prevLink = getPrevStepLink(currentLoc.pathname);

  // eslint-disable-next-line no-unused-vars
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);
  const contactRedux = useSelector((state) => state?.contact?.contact);

  const [bankAccount, setBankAccount] = useState(
    contactRedux?.bankAccountNumber ?? contactRedux?.bankAccount ?? ""
  );

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    if (loadingButton) {return}
    setLoadingButton(!loadingButton);
    // DELETE WHITE SPACE FROM OBJECT VALUE
    trimWhiteSpaceObjValue(data);

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      step: 21,
      lead_id,
      bankAccountNumber: bankAccount
    });

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/driverid");
      }, nextStepDelayTime);
    }
  };
  return (
    <Box className="BankAccount-page">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={20} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={ABAIcon}
                alt=""
              />
            </Box>
            <h2 className="title">Bank Account Number</h2>
            <h5>
              Your account number is a unique number that identifies your
              account and ensures your loan ends up in the right place.
            </h5>
            <div className="form-control">
              {/* bankAccount */}
              <Controller
                name="bankAccount"
                control={control}
                defaultValue={bankAccount}
                rules={{
                  required: "Bank Account is required!",
                  minLength: {
                    value: 4,
                    message: "Please enter at least 4 digits"
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      ...formControlCustom,
                      marginBottom: "20px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>
                      Bank account
                    </FormHelperText>
                    <TextField
                      {...field}
                      inputRef={(input) => {
                        input?.addEventListener("focus", () => {
                          input?.setAttribute("inputmode", "numeric");
                        });
                        ref(input);
                      }}
                      id="bankAccount"
                      size={isMobile ? "small" : "medium"}
                      sx={textFieldCustom}
                      // autoComplete="bankAccount"
                      variant="outlined"
                      fullWidth
                      error={!!errors?.bankAccount}
                      emptylabel="Bank Account"
                      label={bankAccount ? "" : "4 to 30 digits"}
                      helperText={
                        errors?.bankAccount && errors?.bankAccount?.message
                      }
                      InputLabelProps={{
                        shrink: false,
                        style: { ...textInputStyles, color: "#B7B7B7" }
                      }}
                      inputProps={{
                        minLength: 4,
                        maxLength: 24,
                        onInput: (e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          setBankAccount(e.target.value);
                        }
                      }}
                      value={bankAccount}
                    />
                  </FormControl>
                )}
              />
              {/* bankAccount */}
            </div>
            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink={prevLink}
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box>
          </form>
          <StepPreFooter />
        </div>
      </Box>

      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default BankAccount;
