import Home from "./pages/Home/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { useEffect } from "react";
import "./theme.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import LoanReason from "./pages/Steps/LoanReason/LoanReason";
import Cellphone from "./pages/Steps/Cellphone/Cellphone";
import BestTimeContact from "./pages/Steps/BestTimeContact/BestTimeContact";
import Step6 from "./pages/Steps/Step6/Step6";
import NextPayDate from "./pages/Steps/NextPayDate/NextPayDate";
import Step12 from "./pages/Steps/CreditScore/CreditScore";
import Step13 from "./pages/Steps/Step13/Step13";
import Step15 from "./pages/Steps/Step15/Step15";
import Step16 from "./pages/Steps/DriverID/DriverID";
import Step18 from "./pages/Steps/Step18/Step18";
import Step19 from "./pages/Steps/UnsecuredDebtAmount/UnsecuredDebtAmount";
import Step14 from "./pages/Steps/Step14/Step14";

import Unsubscribe from "./pages/AnotherPages/Unsubscribe/Unsubscribe";
import DoNotSellInformation from "./pages/AnotherPages/DoNotSellInformation/DoNotSellInformation";
import TermsOfUse from "./pages/AnotherPages/TermsOfUse/TermsOfUse";
import Privacy from "./pages/AnotherPages/Privacy/Privacy";
import ContactUs from "./pages/AnotherPages/ContactUs/ContactUs";
import Disclaimer from "./pages/AnotherPages/Disclaimer/Disclaimer";
import FrequentlyAskedQuestions from "./pages/AnotherPages/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import Configuration from "./pages/Steps/Configuration/Configuration";
import RedirectPageUpstart from "./pages/Steps/RedirectPageUpstart/RedirectPageUpstart";
import RedirectPageUpgrade from "./pages/Steps/RedirectPageUpgrade/RedirectPageUpgrade";
import RedirectPage from "./pages/Steps/RedirectPage/RedirectPage";
import RejectPage from "./pages/Steps/RejectPage/RejectPage";
import Lgname from "./pages/Steps/Lgname/Lgname";
import Dob from "./pages/Steps/Dob/Dob";
import Address from "./pages/Steps/Address/Address";
import HomeTime from "./pages/Steps/HomeTime/HomeTime";
import OwHome from "./pages/Steps/OwHome/OwHome";
import EmpTime from "./pages/Steps/EmpTime/EmpTime";
import PayFreequency from "./pages/Steps/PayFreequency/PayFreequency";
import GrossIncome from "./pages/Steps/GrossIncome/GrossIncome";
import EmpInfor from "./pages/Steps/EmpInfor/EmpInfor";
import WorkPhone from "./pages/Steps/WorkPhone/WorkPhone";
import IncomeSource from "./pages/Steps/IncomeSource/IncomeSource";
import Deposit from "./pages/Steps/Deposit/Deposit";
import ABARoutingNumber from "./pages/Steps/ABARoutingNumber/ABARoutingNumber";
import BankType from "./pages/Steps/BankType/BankType";
import BankTime from "./pages/Steps/BankTime/BankTime";
import BankAccount from "./pages/Steps/BankAccount/BankAccount";
import DriverID from "./pages/Steps/DriverID/DriverID";
import CreditScore from "./pages/Steps/CreditScore/CreditScore";
import SSN from "./pages/Steps/SSN/SSN";
import RequestCash from "./pages/Steps/RequestCash/RequestCash";
import NextPayDateSort from "./pages/Steps/NextPayDateSort/NextPayDateSort";
import OwnCar from "./pages/Steps/OwnCar/OwnCar";
import UnsecuredDebtAmount from "./pages/Steps/UnsecuredDebtAmount/UnsecuredDebtAmount";
import Agreement from "./pages/AnotherPages/CreditAuthorizationAgreement/Agreement";
import Partners from "./pages/AnotherPages/Partners/Partners";
import EmailContact from "pages/Steps/EmailContact/EmailContact";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000
    });
    AOS.refresh();

    window.onload = () => {
      setTimeout(() => {
        const trustedFormCertUrlEl = document.querySelector('input[name="xxTrustedFormCertUrl"]')
        trustedFormCertUrlEl && sessionStorage.setItem('trustedFormURL', trustedFormCertUrlEl.value);
      }, 500);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/start" />} />
        <Route exact path="/start" element={<Home />}></Route>

        {/* <Route path="/loanreason" element={<LoanReason />}></Route> */}
        {/* <Route path="/amount" element={<Amount />}></Route> */}
        <Route path="/email" element={<EmailContact />}></Route>
        <Route path="/cellphone" element={<Cellphone />}></Route>
        <Route path="/lgname" element={<Lgname />}></Route>
        <Route path="/besttimecontact" element={<BestTimeContact />}></Route>
        <Route path="/dob" element={<Dob />}></Route>
        <Route path="/address" element={<Address />}></Route>
        <Route path="/hometime" element={<HomeTime />}></Route>
        <Route path="/ownhome" element={<OwHome />}></Route>
        <Route path="/incomesource" element={<IncomeSource />}></Route>
        <Route path="/emptime" element={<EmpTime />}></Route>
        <Route
          path="/unsecureddebtamount"
          element={<UnsecuredDebtAmount />}
        ></Route>
        <Route path="/payfreequency" element={<PayFreequency />}></Route>
        <Route path="/grossincome" element={<GrossIncome />}></Route>
        <Route path="/nextpaydate" element={<NextPayDate />}></Route>
        <Route path="/nextpaydatesort" element={<NextPayDateSort />}></Route>
        <Route path="/empinfor" element={<EmpInfor />}></Route>
        <Route path="/workphone" element={<WorkPhone />}></Route>
        <Route path="/deposit" element={<Deposit />}></Route>
        <Route path="/aba" element={<ABARoutingNumber />}></Route>
        <Route path="/banktype" element={<BankType />}></Route>
        <Route path="/banktime" element={<BankTime />}></Route>
        <Route path="/bankaccount" element={<BankAccount />}></Route>
        <Route path="/driverid" element={<DriverID />}></Route>
        <Route path="/owncar" element={<OwnCar />}></Route>
        <Route path="/creditscore" element={<CreditScore />}></Route>
        <Route path="/ssn" element={<SSN />}></Route>
        <Route path="/requestcash" element={<RequestCash />}></Route>
        <Route path="/mapping-lender" element={<Configuration />}></Route>

        <Route path="/apply/home_time" element={<Step6 />}></Route>
        <Route path="/apply/score" element={<Step12 />}></Route>
        <Route path="/apply/bank" element={<Step13 />}></Route>
        <Route path="/apply/deposit" element={<Step14 />}></Route>
        <Route path="/apply/bank_time" element={<Step15 />}></Route>
        <Route path="/apply/driver" element={<Step16 />}></Route>
        <Route path="/apply/ssn" element={<Step18 />}></Route>
        <Route path="/apply/debt" element={<Step19 />}></Route>
        <Route path="/unsubscribe" element={<Unsubscribe />}></Route>
        <Route
          path="/do-not-sell-information"
          element={<DoNotSellInformation />}
        ></Route>
        <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/disclaimer" element={<Disclaimer />}></Route>
        <Route
          path="/frequently-asked-questions"
          element={<FrequentlyAskedQuestions />}
        ></Route>
        <Route path="/configuration" element={<Configuration />}></Route>
        <Route
          path="/redirect-upstart"
          element={<RedirectPageUpstart />}
        ></Route>
        <Route
          path="/redirect-upgrade"
          element={<RedirectPageUpgrade />}
        ></Route>
        <Route path="/redirect" element={<RedirectPage />}></Route>
        <Route path="/reject-page" element={<RejectPage />}></Route>
        <Route
          path="/credit-authorization-agreement"
          element={<Agreement />}
        ></Route>
        <Route path="/partners" element={<Partners />}></Route>
      </Routes>
    </Router>
  );
};
export default App;
