import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
// import Footer from "../../../components/Footer/Footer";

import "./Address.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import { StepPreFooter } from "../../../components/Footer/StepPreFooter";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import addressIcon from "../../../assets/images/step-icon/address-icon.png";

import { updateContact } from "../../../redux/apiCalls";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  TextField
} from "@mui/material";
import {
  ListBoxCustom,
  formControlCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles
} from "./style";

import { trimWhiteSpaceObjValue, getPrevStepLink } from "../../../utils/functions";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { nextStepDelayTime } from "../../../utils/const";
import { inHousePublicRequest, zipCodeRequest } from "../../../requestMethods";

const Address = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const tempContact = useSelector((state) => state?.contact?.temporary);
  const [openStreetAddressOptions, setOpenStreetAddressOptions] =
    useState(false);

  const [loadingZip, setLoadingZip] = useState(false);
  const [zipCode, setZipCode] = useState(tempContact?.zip ?? contactRedux?.zip ?? contactRedux?.zipCode ?? "");
  const [city, setCity] = useState(contactRedux?.city ?? "");
  const [state, setState] = useState(contactRedux?.state ?? "");
  const [zipPosition, setZipPosition] = useState(contactRedux?.zipPosition ?? [])
  const [streetAddress, setStreetAddress] = useState(
    contactRedux?.address ?? contactRedux?.streetAddress ?? ""
  );
  const [addressOptions, setAddressOptions] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);

  const currentLoc = useLocation();
  const prevLink = getPrevStepLink(currentLoc.pathname);

  // CHECK ZIP
  const checkZipCode = async (zip) => {
    setLoadingZip(!loadingZip);
    
    const res = await zipCodeRequest.get(
      "",
      { params: { lead_id: zipCode, zip: zipCode} }
    );

    setTimeout(() => {
      setLoadingZip(false);

      const result = res?.data?.data;

      if (result?.city) {
        setState(result?.state);
        setCity(result?.city);
        setZipPosition([result?.lat, result?.long])
        clearErrors("zipCode");

        return true;
      } else {
        setState("");
        setCity("");
        setError("zipCode", {
          type: "required",
          message: "Invalid zip code!"
        });
        return "Invalid zip code!";
      }
    }, 500);
  };

  const validateAddress = (addressValue) => {
    const regex = /^[a-zA-Z0-9\s\-/'()&]+$/;
    if (regex.test(addressValue)) {
      clearErrors("streetAddress");
    } else {
      setError("streetAddress", {
        type: "custom",
        message: "The address can not contain special characters!"
      });
    }
    return regex.test(addressValue);
  };

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    if (loadingButton) {return}
    setLoadingButton(true);

    if (state === "" || city === "") {
      const isValid = await checkZipCode(zipCode)
      if (isValid === true) {
        clearErrors("zipCode");
      } else {
        setError("zipCode", {
          type: "required",
          message: isValid
        });
        setLoadingButton(false);
        return;
      }
    } else {
      clearErrors("zipCode");
    }

    if (!validateAddress(streetAddress)) {
      setLoadingButton(false)
      return
    };

    data.state = state ?? "";
    data.city = city ?? "";

    // DELETE WHITE SPACE FROM OBJECT VALUE
    trimWhiteSpaceObjValue(data);

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      step: 6,
      lead_id,
      zip: zipCode,
      state,
      city,
      address: streetAddress
    });

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/hometime");
      }, nextStepDelayTime);
    }
  };

  const callApiCheckAddress = async (value) => {
    const lead_id = sessionStorage.getItem("lead_id")
    if (value.length > 1) {
      const res = await inHousePublicRequest.get('/personal_loan/address-suggest', {
          params: {
            zip: zipCode,
            address: value,
            lead_id: lead_id,
            lat: zipPosition[0],
            long: zipPosition[1],
            max_results: 5
          }
        }
      );
      res?.data?.suggestions && setAddressOptions(res.data.suggestions);
    }
  };

  // Thực hiện gọi API khi ngừng nhập sau 1 giây
  const handleChangeStreetAddress = (value) => {
    // setInputValue(value);

    // Hủy bỏ bất kỳ timeout cũ nào
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Thiết lập timeout mới
    const newTimeout = setTimeout(() => {
      callApiCheckAddress(value);
    }, 300);

    setTypingTimeout(newTimeout);
  };

  return (
    <Box className="address-page">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={5} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={addressIcon}
                alt=""
              />
            </Box>
            <h2 className="title">Address of Residence</h2>
            <div className="form-control">
              {/* <FormHelperText sx={labelCustom}>Your Zip Code</FormHelperText> */}
              {/* <Controller
                name="zipCode"
                control={control}
                defaultValue={zipCode}
                rules={{
                  required: "Zip code is required!",
                  minLength: {
                    value: 5,
                    message: "Zip code must be 5 characters long!"
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={(input) => {
                      input?.addEventListener("focus", () => {
                        input?.setAttribute("inputmode", "numeric");
                      });
                      ref(input);
                    }}
                    id="zipCode"
                    sx={{ ...textFieldCustom, marginBottom: "7px" }}
                    // autoComplete="zipCode"
                    variant="outlined"
                    fullWidth
                    type="text"
                    error={!!errors?.zipCode}
                    label={zipCode ? " " : "e.g. 90011"}
                    helperText={errors?.zipCode && errors?.zipCode?.message}
                    inputProps={{
                      maxLength: 5
                    }}
                    InputLabelProps={{
                      shrink: false,
                      style: { ...textInputStyles, color: "#B7B7B7" }
                    }}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      field.onChange(value);
                      setZipCode(value);
                      value.length === 5 && checkZipCode(value);
                    }}
                    value={zipCode}
                    size={isMobile ? "small" : "medium"}
                    InputProps={{
                      endAdornment: loadingZip && (
                        <InputAdornment position="end">
                          <CircularProgress
                            size={windowWidth > 768 ? 20 : 15}
                            color="inherit"
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              /> */}

              {city && state && (
                <div className="box-state">
                  <LocationOnIcon className="address-icon" sx={{ width: 20 }} />
                  <p className="state">
                    {city}, {state}
                  </p>
                </div>
              )}

              {/* streetAddress */}
              <Controller
                name="streetAddress"
                control={control}
                defaultValue={streetAddress}
                rules={{
                  required: "Street Address is required!"
                }}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      ...formControlCustom,
                      marginBottom: "15px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>
                      Street address
                    </FormHelperText>
                    <Autocomplete
                      {...field}
                      freeSolo
                      id="streetAddress"
                      name="streetAddress"
                      onOpen={() => setOpenStreetAddressOptions(true)}
                      onClose={() => setOpenStreetAddressOptions(false)}
                      disableClearable
                      value={streetAddress}
                      options={addressOptions}
                      onChange={(e, option) => {
                        setStreetAddress(option);
                      }}
                      onInputChange={(event, newInputValue) => {
                        setStreetAddress(newInputValue);
                        field.onChange(newInputValue);
                      }}
                      ListboxComponent="div"
                      ListboxProps={{ sx: ListBoxCustom }}
                      //PopperComponent={isMobile && "bottom"}
                      noOptionsText={false}
                      renderOption={(props, option, optionsObj) => {
                        return (
                          <MenuItem {...props} key={optionsObj.index}>
                            {option}
                          </MenuItem>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={streetAddress ? "" : "e.g. Your address"}
                          size={isMobile ? "small" : "medium"}
                          sx={textFieldCustom}
                          className={
                            openStreetAddressOptions &&
                            addressOptions.length > 0
                              ? "show"
                              : ""
                          }
                          InputLabelProps={{
                            shrink: false,
                            style: { ...textInputStyles, color: "#B7B7B7" }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            type: "search"
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            validateAddress(value);
                            handleChangeStreetAddress(value);
                          }}
                          error={!!errors?.streetAddress}
                          helperText={
                            errors?.streetAddress &&
                            errors?.streetAddress?.message
                          }
                        />
                      )}
                    />
                  </FormControl>
                )}
              />

              {/* streetAddress */}
              <Box
                sx={{
                  maxWidth: "474px",
                  margin: "0 auto"
                }}
              >
                <StepBtn
                  prevLink={prevLink}
                  onSubmit={handleSubmit(onSubmit)}
                  loading={loadingButton}
                />
              </Box>
            </div>
          </form>
          <StepPreFooter />
        </div>
      </Box>
      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default Address;
