import React, { useRef, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";

import Footer from "../../../components/Footer/Footer";

import "./EmailContact.scss";
import Progress from "../../../components/Progress/Progress";
import { StepPreFooter } from "../../../components/Footer/StepPreFooter";

import emailIcon from "../../../assets/images/step-icon/email-icon.png";

import { Controller, useForm } from "react-hook-form";
import { addContact } from "../../../redux/apiCalls";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { Box, FormHelperText, TextField } from "@mui/material";
import { Autocomplete, FormControl } from "@mui/material";
// CUSTOM STYLE
import { MarketingPartners } from "../../../components/popups/MarketingPartners/MarketingPartners";
import {
  ListBoxCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles
} from "./style";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { PopupStep } from "../../../components/PopupStep/PopupStep";

import questionIcon from "../../../assets/images/step-icon/question.png";
import { emailDomainsOptions } from "../../../utils/options";
import { checkLastStep } from "../../../utils/checkLastStep";
import { convertContactFromResponse, getPrevStepLink } from "../../../utils/functions";

const EmailContact = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [newEmail, setNewEmail] = useState(
    sessionStorage.getItem("newEmail") === "true" ? true : false
  );

  const emailInputRef = useRef(null);


  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);
  const [opentMarketingPartners, setOpentMarketingPartners] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const tempContact = useSelector((state) => state?.contact?.temporary);

  const [emailOptions, setEmailOptions] = useState([]);
  const [openEmailOptions, setOpenEmailOptions] = useState(false);
  const [email, setEmail] = useState(contactRedux?.email ?? "");

  const currentLoc = useLocation();
  const prevLink = getPrevStepLink(currentLoc.pathname);

  const handleInputChange = (e, newInputValue) => {
    if (newInputValue.includes("@")) {
      const [email, domain] = newInputValue.split("@");
      const filteredOptions = emailDomainsOptions
        .filter((option) => option.includes(domain))
        .map((option) => `${email}@${option}`);
      setEmailOptions(filteredOptions);
    } else {
      setEmailOptions([]);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    if (loadingButton) {return}
    setLoadingButton(!loadingButton);
    try {
      const res = await addContact(dispatch, {...data, ...tempContact});
      if (res?.status === 200) {
        const convertedContact = convertContactFromResponse(
          res.data.data.contact
        );
        const nextStep = checkLastStep(convertedContact) ?? 'nextpaydatesort';
        navigate(`/${nextStep}`);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error as needed
    }
  };

  return (
    <>
      {newEmail && (
        <PopupStep
          icon={questionIcon}
          title="Please double-check your email address is correct"
          email={email}
          buttonsDirection="rows"
          buttons={[
            {
              title: "Confirm",
              onClick: () => {
                navigate("/cellphone");
              }
            },
            {
              title: "Edit",
              onClick: () => {
                setNewEmail(false);
                emailInputRef.current.focus();
              }
            }
          ]}
        />
      )}

      {opentMarketingPartners && (
        <MarketingPartners
          opentMarketingPartners={opentMarketingPartners}
          setOpentMarketingPartners={setOpentMarketingPartners}
        />
      )}

      <Box
        className="email-contact"
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",

          // background:
          //   "linear-gradient(\n    180deg,\n    #f1ffec 0.56%,\n    rgba(241, 255, 236, 0) 57.94%\n  )"
        }}
      >
        <Navbar />
        <Box
          sx={{
            width: "100%",
            maxWidth: "700px",
            margin: "20px auto"
          }}
        >
          <div className="step-main">
            <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  width: "100%",
                  margin: "10px auto 30px",
                  padding: isMobile && "0 15px"
                }}
              >
                <Progress step={3} />
              </Box>
              <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
                <img src={emailIcon} alt="" />
              </Box>
              <h2 className="title">Your contact Email</h2>
              <h5>
                Where would you like to receive your loan request updates?
              </h5>
              <div className="form-control">
                <Controller
                  name="email"
                  control={control}
                  defaultValue={email}
                  rules={{
                    required: "Email is required!",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address"
                    }
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <FormControl
                      fullWidth
                      sx={{
                        marginBottom: isMobile ? "7px" : "15px"
                      }}
                    >
                      <FormHelperText sx={labelCustom}>
                        Your email address
                      </FormHelperText>
                      <Autocomplete
                        {...field}
                        freeSolo
                        id="email"
                        name="email"
                        onOpen={() => setOpenEmailOptions(true)}
                        onClose={() => setOpenEmailOptions(false)}
                        disableClearable
                        value={email}
                        options={emailOptions}
                        onChange={(e, option) => {
                          setEmail(option);
                        }}
                        onInputChange={(event, newInputValue) => {
                          handleInputChange(event, newInputValue);
                          field.onChange(newInputValue);
                        }}
                        ListboxComponent="div"
                        ListboxProps={{ sx: ListBoxCustom }}
                        //PopperComponent={isMobile && "bottom"}
                        noOptionsText={false}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputRef={emailInputRef}
                            label={email ? "" : "e.g. john123@gmail.com"}
                            size={isMobile ? "small" : "medium"}
                            sx={{
                              ...textFieldCustom
                            }}
                            className={
                              openEmailOptions && emailOptions.length > 0
                                ? "show"
                                : ""
                            }
                            InputLabelProps={{
                              shrink: false,
                              style: {
                                ...textInputStyles,
                                color: "#B7B7B7"
                              }
                            }}
                            value={email}
                            error={!!errors?.email}
                            helperText={errors?.email && errors?.email?.message}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />
              </div>
              <p className="text">
                By clicking "Next Step", you agree to our <a href="/terms-of-use">Terms & Conditions</a>, <a href="/privacy">Privacy Policy</a>, <a href="/credit-authorization-agreement">Credit Authorization</a> and to receive important notices and other communications electronically.
              </p>
              <Box
                sx={{
                  maxWidth: "474px",
                  margin: "0 auto"
                }}
              >
                <StepBtn
                  prevLink={prevLink}
                  onSubmit={handleSubmit(onSubmit)}
                  loading={loadingButton}
                />
              </Box>
            </form>
            <StepPreFooter />
          </div>
        </Box>
        {/* <Footer desc={false} /> */}
      </Box>
    </>
  );
};

export default EmailContact;
