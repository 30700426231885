import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";

import "./Lgname.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import { StepPreFooter } from "../../../components/Footer/StepPreFooter";

import { Controller, useForm } from "react-hook-form";
import { updateTemporaryContact } from "../../../redux/contactRedux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  Autocomplete,
  Box,
  FormControl,
  MenuItem,
  FormHelperText,
  TextField,
  Select
} from "@mui/material";

import {
  searchFirstNameApi,
  searchLastNameApi,
} from "../../../utils/callApi";

import step2Icon from "../../../assets/images/step-icon/step2-icon.png";

import {
  ListBoxCustom,
  MenuProps,
  formControlCustom,
  labelCustom,
  selectCustom,
  menuItemCustom,
  textFieldCustom,
  textInputStyles
} from "./style";

import { trimWhiteSpaceObjValue } from "../../../utils/functions";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { amountOptions } from "../../../utils/options";
import { nextStepDelayTime } from "../../../utils/const";

const Lgname = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = window.innerWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [firstName, setFirstName] = useState(contactRedux?.firstName ?? "");
  const [firstNameOptions, setFirstNameOptions] = useState([]);
  const [openFirstName, setOpenFirstName] = useState(false);

  const [lastName, setLastName] = useState(contactRedux?.lastName ?? "");
  const [lastNameOptions, setLastNameOptions] = useState([]);
  const [openLastName, setOpenLastName] = useState(false);

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [amount, setAmount] = useState(
    contactRedux?.amount ?? amountOptions[3]?.value
  );
  const loanReasonFromURL = sessionStorage.getItem("loanReasonFromURL");

  const timeoutRef = useRef(null);
  // call api search firstName
  const handleSearchFirstName = async (firstName) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const isValidInput = firstName.length > 0 && firstName.length < 50;
    // Wait for 1 second before making the API call
    timeoutRef.current = setTimeout(async () => {
      if (isValidInput) {
        const res = await searchFirstNameApi(firstName);
        setFirstNameOptions(res ?? []);
      }
    }, 300);
  };
  // call api search lastName
  const handleSearchLastName = async (lastName) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const isValidInput = lastName.length > 0 && lastName.length < 50;

    // Wait for 1 second before making the API call
    timeoutRef.current = setTimeout(async () => {
      if (isValidInput) {
        const res = await searchLastNameApi(lastName);
        setLastNameOptions(res ?? []);
      }
    }, 300);
  };

  useEffect(() => {
    isMobile && window.scrollTo(0, 66);
  }, [isMobile]);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const handleFormSubmit = async (data) => {
    if (loadingButton) {return false}
    setLoadingButton(true);
    try {
      trimWhiteSpaceObjValue(data);

      dispatch(updateTemporaryContact(data));

      setTimeout(() => {
        navigate("/email");
      }, nextStepDelayTime);

    } catch (error) {
      console.error("Error updating contact:", error);
      setLoadingButton(false);
    } finally {
      setLoadingButton(false);
    }
  };
  // const onSubmit = handleSubmit(handleFormSubmit);

  return (
    <Box className="lgname">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto 0"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(handleFormSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={2} />
            </Box>

            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={step2Icon}
                alt=""
              />
            </Box>
            <h2 className="title">Your Legal Name</h2>
            <div className="form-control">
              {/* loanAmount */}
              {loanReasonFromURL && (
                <Controller
                  name="amount"
                  defaultValue={amount}
                  control={control}
                  rules={{ required: "Loan amount is required!" }}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      error={!!errors?.amount}
                      size={isMobile ? "small" : "medium"}
                      sx={{
                        marginBottom: isMobile ? "7px" : "15px"
                      }}
                    >
                      <FormHelperText sx={labelCustom}>
                        Loan Amount ($100 - $5,000)
                      </FormHelperText>
                      <Select
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(value);
                          setAmount(value);
                        }}
                        value={amount}
                        displayEmpty
                        MenuProps={{
                          ...MenuProps,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                          }
                        }}
                        sx={selectCustom}
                        onClose={() => {
                          setIsSelectOpen(false);
                        }}
                        onOpen={() => {
                          setIsSelectOpen(true);
                        }}
                        className={isSelectOpen ? "show" : ""}
                      >
                        {amountOptions.map((option, index) => (
                          <MenuItem
                            key={index}
                            value={option.value}
                            sx={menuItemCustom}
                            size="small"
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors?.amount && errors?.amount.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              )}
              {/* firstName */}
              <Controller
                name="firstName"
                control={control}
                defaultValue={firstName}
                rules={{
                  required: "FirstName is required!"
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      ...formControlCustom,
                      marginBottom: "7px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>First name</FormHelperText>
                    <Autocomplete
                      {...field}
                      freeSolo
                      id="firstName"
                      name="firstName"
                      // autoComplete="off"
                      onOpen={() => setOpenFirstName(true)}
                      onClose={() => setOpenFirstName(false)}
                      disableClearable
                      value={firstName}
                      options={firstNameOptions}
                      onChange={(e, option) => {
                        setFirstName(option);
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleSearchFirstName(newInputValue);
                        field.onChange(newInputValue);
                      }}
                      ListboxComponent="div"
                      ListboxProps={{ sx: ListBoxCustom }}
                      //PopperComponent={isMobile && "bottom"}
                      noOptionsText={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputRef={ref}
                          label={firstName ? " " : "e.g. John"}
                          size={isMobile ? "small" : "medium"}
                          sx={textFieldCustom}
                          className={
                            openFirstName && firstNameOptions.length > 0
                              ? "show"
                              : ""
                          }
                          InputLabelProps={{
                            shrink: false,
                            style: { ...textInputStyles, color: "#B7B7B7" }
                          }}
                          value={firstName}
                          error={!!errors?.firstName}
                          helperText={
                            errors?.firstName && errors?.firstName?.message
                          }
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
              {/* firstName */}
              <Controller
                name="lastName"
                control={control}
                defaultValue={lastName}
                rules={{
                  required: "lastName is required!"
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      ...formControlCustom,
                      marginBottom: "20px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>Last name</FormHelperText>
                    <Autocomplete
                      {...field}
                      freeSolo
                      id="lastName"
                      name="lastName"
                      onOpen={() => setOpenLastName(true)}
                      onClose={() => setOpenLastName(false)}
                      disableClearable
                      value={lastName}
                      options={lastNameOptions}
                      onChange={(e, option) => {
                        setLastName(option);
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleSearchLastName(newInputValue);
                        field.onChange(newInputValue);
                      }}
                      ListboxComponent="div"
                      ListboxProps={{ sx: ListBoxCustom }}
                      //PopperComponent={isMobile && "bottom"}
                      noOptionsText={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputRef={ref}
                          label={lastName ? " " : "e.g. Smith"}
                          sx={textFieldCustom}
                          className={
                            openLastName && lastNameOptions.length > 0
                              ? "show"
                              : ""
                          }
                          InputLabelProps={{
                            shrink: false,
                            style: { ...textInputStyles, color: "#B7B7B7" }
                          }}
                          size={isMobile ? "small" : "medium"}
                          value={lastName}
                          error={!!errors?.lastName}
                          helperText={
                            errors?.lastName && errors?.lastName?.message
                          }
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
              {/* lastName */}
              <Box
                sx={{
                  maxWidth: "474px",
                  margin: "0 auto"
                }}
              >
                <StepBtn
                  prevLink="/"
                  onSubmit={handleSubmit(handleFormSubmit)}
                  loading={loadingButton}
                />
              </Box>
            </div>
          </form>
          <StepPreFooter />
        </div>
      </Box>
      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default Lgname;
