import React, { useRef, useEffect } from "react";
import "./FAQs.scss";
import close from "../../../assets/images/close1.png";

import bankImage from "../../../assets/images/FAQ/img1.png";

export const FAQs = ({ openFAQs, setOpenFAQs }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpenFAQs(false);
      }
    };

    if (openFAQs) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openFAQs, setOpenFAQs]);

  return (
    <div className={`FAQs ${openFAQs ? "open" : ""}`}>
      <div className="box-popup">
        <div className="container">
          <div className="content-popup" ref={popupRef}>
            <h2 className="title">Frequently Asked Questions</h2>

            <h4>1. What is Quicklyfincal Loans?</h4>
            <p>
              <b>Quicklyfincal Loans</b> is a cash advance and personal loan
              online referral service on our website.
            </p>
            <p>
              If a customer is looking for a cash advance or personal loan
              online, we help connect them with a marketplace of companies who
              offer personal loans and cash advances. Our website features a
              secure form requesting information that lenders and/or lending
              partners within our cash advance and personal loan referral
              marketplace will use to decide whether they want to offer a
              customer a cash advance or personal loan. The information that
              they need in order to make cash advance or personal loan decisions
              about each customer includes name, address, home and work phone
              numbers, and email address. They also need a customer's birth date
              and social security number to comply with the US Patriot Act. The
              operator of this website can be reached by mail at Quicklyfincal
              Loans, 2803 Philadelphia Pike, Suite B #1020, Claymont, DE 19703,
              United States or by email. Customers can contact us if they want
              to discontinue using our loan referral service, or to change their
              communication preferences. Any questions about cash advance or
              personal loan amounts should be directed to the company from which
              a customer obtained his or her cash advance or personal loan.
            </p>
            <h4>2. How much will the loan cost?</h4>
            <p>
              The cost of the loan and Annual Percentage Rate (APR) depend on
              several factors including but not limited to, credit history,
              stable source of income, and state law. Your APR also depends on
              how much you want to borrow and how quickly you want to repay the
              loan.
              <br />
              The terms of your loan are disclosed during the loan request
              process when you are directed to the lender's and/or lending
              partner's loan agreement. This information is strictly between you
              and your lender and/or lending partner. Not all customers will be
              eligible for a loan or meet the criteria to receive the best terms
              or lowest interest rate.
            </p>
            <h4>
              3. How soon can a personal loan or cash advance be made available?
            </h4>
            <p>
              The time it takes to process a loan and transfer or ACH funds into
              an individual's account varies with each loan company, and also
              depends on the financial institution where the customer has their
              bank account. However, approved individuals can often receive
              their loan as soon as the next business day after approval.
            </p>
            <h4>4. Why are you collecting my SSN and Bank Details?</h4>
            <p>
              Our network of lenders use your SSN and bank details to help them
              make a decision about whether or not to extend you a loan offer.
              The bank details also help the lender that you are connected to
              know how to send money to your account. Our websites are encrypted
              using industry recognized encryption technology to help keep your
              information safe. Additionally, lenders use the SSN to help ensure
              the identity of the applicant is really the person they say they
              are.
            </p>
            <h4>5. Are you a lender?</h4>
            <p>
              No, we are not a lender. We work with several lenders to make up a
              network that can help you to find a loan. After submitting a
              request, if accepted, we will forward you to the lender's website
              where you will be able to learn more about your specific loan
              offer.
            </p>
            <h4>6. What if I have bad credit?</h4>
            <p>
              We attempt to connect consumers with lenders regardless of your
              credit history. Requirements needed for a loan approval is based
              on each individual lender. Please review our How It Works page to
              determine if you have the basic requirements to qualify.
            </p>
            <h4>
              7. Where do I find my ABA / Routing Number &amp; account number?
            </h4>
            <p>
              Please see the diagram below for instructions on how to find the
              information from your check book. Alternatively please call your
              bank for more information.
            </p>
            <div
              style={{
                textAlign: "center"
              }}
            >
              <img src={bankImage} alt="" />
            </div>
            <h4>
              8. What Happens If I Do Not Pay (Implications Of Non-Payment)?
            </h4>
            <p>
              <b>Late/Partial Payments:</b> If you do not pay the full amount of
              principal and interest by the agreed-upon payment date, you will
              likely be charged a new finance charge and payment date may be
              extended until your next pay date. This new finance charge may be
              a flat fee, or may be calculated by applying the proportionate
              amount of the finance charge to the unpaid loan amount. Review the
              late payment policy detailed in the loan documents provided to you
              by your lender and/or lending partner before signing your
              e-signature, and thereby agreeing to the loan terms.
            </p>
            <p>
              <b>No-Payment:</b> Quicklyfincal Loans does not enforce payment.
              If you do not pay, your lender and/or lending partner may take
              legal action against you. Additional fees may apply to you in the
              event that you are unable to repay your loan. Information
              regarding your payment history, delinquency or defaults on the
              account may be reported to a third party consumer reporting agency
              and may impact your credit rating.
            </p>
            <p>
              <b>Loan Renewals:</b> Quicklyfincal Loans will not provide a
              renewal for you. Your lender and/or lending partner has renewal
              policies which will vary. With every extension or renewal, a new
              finance charge ("Extension Fee") may be assessed by the lender
              and/or lending partner, and the re-payment date may be extended
              until the borrower's next pay date. The finance charges can be
              significant depending on the lender and/or lending partner.
            </p>
            <p>
              <b>Collection Practices:</b> Collections practices of lenders
              and/or lending partners and/or any assignee(s) will be in
              accordance with the principles of applicable federal regulations.
              Lenders and/or lending partners may attempt to contact borrowers
              via one or more authorized methods, including phone calls and
              emails, to arrange for payment. Impact to Credit Score: Your
              lender and/or lending partner may report late or missed payments
              to the credit bureaus. This can negatively affect your credit
              score. Consumers with credit difficulties should seek credit
              counseling.
            </p>
            <h4>9. What Are Personal Loans Or Installment Loans?</h4>
            <p>
              Personal and/or installment loans typically range between $100 and
              $5,000. They can be used for almost any personal expense, from
              paying off credit cards and medical bills to financing home
              repairs or even a much-needed vacation. Personal loans are
              commonly called installment loans because they are typically paid
              back in monthly or bi-monthly installments over an extended period
              of time. They can be a convenient alternative to bank loans or
              high-interest credit cards, with online loan request forms and
              no-hassle automated repayment.
            </p>
            <h4>10. How Much Can I Receive?</h4>
            <p>
              Qualified loan amounts can vary and are based on personal
              eligibility. Approved loan amounts can range between $100 to
              $5,000.
            </p>
            <h4>11. What is the cost?</h4>
            <p>
              There is no cost to use Quicklyfincal.com. The fees of the
              loan will vary depending upon the amount and the lender. The
              lender will inform you of the fees for the loan which may vary
              depending on the lender.
            </p>
            <h4>12. Loan Renewal Policies</h4>
            <p>
              Loan renewal options are not always available. It is therefore
              advisable to clarify whether the option is available with your
              lender. Be sure to carefully peruse the renewal policy presented
              in the agreement before you sign the documents.
            </p>
            <h4>13. Representative Example of APR</h4>
            <p>
              If you borrow $2,500 over a term of 1 year with an APR of 10% and
              a fee of 3%, you will pay $219,79 each month. The total amount
              payable will be $2,637, with a total interest of $137,48.
            </p>
            <h4>14. Collection Practices</h4>
            <p>
              Quicklyfincal Loans is not a lender. As such, we are not involved
              in any debt collection practices and cannot make you aware of any
              of them. Your lender will specify their collection practices in
              your loan agreement. If you have any questions regarding the
              issue, please, address them to your lender. For more information
              visit our page for Responsible Lending. Quicklyfincal.com
              itself isn&rsquo;t involved in debt collection.
            </p>
          </div>
          <button className="close" onClick={() => setOpenFAQs(!openFAQs)}>
            <img src={close} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};
