import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
// import Footer from "../../../components/Footer/Footer";

import "./HomeTime.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import checkedIcon from "../../../assets/images/checked.png";
import HomeTimeIcon from "../../../assets/images/step-icon/hometime-icon.png";
import { StepPreFooter } from "../../../components/Footer/StepPreFooter";

import { useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { currentResidenceDurationOptions } from "../../../utils/options";
import { nextStepDelayTime } from "../../../utils/const";
import { getPrevStepLink } from "../../../utils/functions";

const HomeTime = () => {
  // eslint-disable-next-line no-unused-vars
  const [loadingButton, setLoadingButton] = useState(false);
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [currentResidenceDuration, setCurrentResidenceDuration] = useState(
    contactRedux?.time_at_residence ?? ""
  );
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentLoc = useLocation();
  const prevLink = getPrevStepLink(currentLoc.pathname);

  const convertYearToMonth = (yearString) =>
    ({
      "10+ Years": 120,
      "9 Years": 108,
      "8 Years": 96,
      "7 Years": 84,
      "6 Years": 72,
      "5 Years": 60,
      "4 Years": 48,
      "3 Years": 36,
      "2 Years": 24,
      "1 Year": 12
    }[yearString]);

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  const onSubmit = async () => {
    if (!currentResidenceDuration) {
      setError("currentResidenceDuration", {
        type: "invalid",
        message: "Please select one option."
      });
      return;
    }
    setLoadingButton(true);

    if (!currentResidenceDuration) {
      setError("currentResidenceDuration", {
        type: "invalid",
        message: "Please select one option."
      });
      return;
    }

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      step: 7,
      lead_id,
      time_at_residence: currentResidenceDuration,
      addressLengthMonths: convertYearToMonth(currentResidenceDuration)
    });

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);

        navigate("/ownhome");
      }, nextStepDelayTime);
    }
  };

  return (
    <Box className="hometime-page">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={6} />
            </Box>

            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={HomeTimeIcon}
                alt=""
              />
            </Box>
            <h2 className="title">Time at Current Residence</h2>

            <div className="form-control">
              <div className="list-btn-radio">
                {currentResidenceDurationOptions.map((option, index) => (
                  <button
                    key={index}
                    className={`btn-radio ${
                      currentResidenceDuration === option ? "active" : ""
                    }`}
                    onClick={(e) => {
                      setCurrentResidenceDuration(option);
                      clearErrors("currentResidenceDuration");
                    }}
                  >
                    {option}
                    {currentResidenceDuration === option && (
                      <img className="checked-icon" src={checkedIcon} alt="" />
                    )}
                  </button>
                ))}
              </div>

              {errors?.currentResidenceDuration && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "10px",
                    display: "block",
                    textAlign: "center"
                  }}
                  className="error"
                >
                  {errors.currentResidenceDuration.message}
                </span>
              )}
            </div>

            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink={prevLink}
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box>
          </form>
          <StepPreFooter />
        </div>
      </Box>
      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default HomeTime;
