import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";

// import Footer from "../../../components/Footer/Footer";

import "./Cellphone.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import step3Icon from "../../../assets/images/step-icon/step3-icon.png";
import { StepPreFooter } from "../../../components/Footer/StepPreFooter";

import { Controller, useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { Box, FormHelperText, TextField } from "@mui/material";
import InputMask from "react-input-mask";
// CUSTOM STYLE
import { MarketingPartners } from "../../../components/popups/MarketingPartners/MarketingPartners";
import { labelCustom, textFieldCustom } from "./style";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";

import { nextStepDelayTime, phonePattern } from "../../../utils/const";
import { getPrevStepLink } from "../../../utils/functions";

const Cellphone = () => {
  const navigate = useNavigate();
  const dispath = useDispatch();

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);
  const [opentMarketingPartners, setOpentMarketingPartners] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [phone, setPhone] = useState(contactRedux?.cellPhone ?? contactRedux?.phone ?? "");

  const currentLoc = useLocation();
  const prevLink = getPrevStepLink(currentLoc.pathname);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm();
  const onSubmit = async (data) => {
    try {
      if (loadingButton) {return}
      setLoadingButton(!loadingButton);

      // check phone number before submit form
      if (data?.phone) {
        const phoneFormatted = data?.phone.replace(/[-()_]/g, "");
        const lead_id = sessionStorage.getItem("lead_id");
        const res = await updateContact(dispath, contactRedux?._id, {
          step: 2,
          lead_id,
          cellPhone: phoneFormatted,
          homePhone: phoneFormatted,
          contactTime: "ANYTIME"
        });

        if (res?.status === 200) {
          setTimeout(() => {
            setLoadingButton(false);
            navigate("/dob");
          }, nextStepDelayTime);
        } else {
          setLoadingButton(false);
          setError("phone", {
            type: "error",
            message: "Phone number is invalid!"
          });
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      {opentMarketingPartners && (
        <MarketingPartners
          opentMarketingPartners={opentMarketingPartners}
          setOpentMarketingPartners={setOpentMarketingPartners}
        />
      )}

      <Box
        className="cellphone"
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <Navbar />
        <ScrollToTop />
        <Box
          sx={{
            width: "100%",
            maxWidth: "700px",
            margin: "20px auto"
          }}
        >
          <div className="step-main">
            <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  width: "100%",
                  margin: "10px auto 30px",
                  padding: isMobile && "0 15px"
                }}
              >
                <Progress step={3} />
              </Box>
              <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
                <img src={step3Icon} alt="" />
              </Box>
              <h2 className="title">Cell phone number</h2>
              <h5>Lenders might call you to discuss your loan request</h5>
              <div className="form-control">
                <FormHelperText sx={labelCustom}>
                  Your phone number
                </FormHelperText>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue={phone}
                  rules={{
                    required: "Phone number is required!",
                    pattern: {
                      value: phonePattern,
                      message: "The phone number is not correct!"
                    }
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <InputMask {...field} mask="(999)-999-9999" maskChar="_">
                      {() => (
                        <TextField
                          {...field}
                          inputRef={(input) => {
                            input?.addEventListener("focus", () => {
                              input?.setAttribute("inputmode", "numeric");
                            });
                            ref(input);
                          }}
                          fullWidth
                          sx={{ ...textFieldCustom, marginBottom: "13px" }}
                          size={isMobile ? "small" : "medium"}
                          error={!!errors?.phone}
                          helperText={errors?.phone && errors?.phone?.message}
                          placeholder="e.g. (123)-123-1234 "
                          InputLabelProps={{ shrink: false }}
                          onInput={(e) => setPhone(e.target.value)}
                          // autoComplete="phone"
                        />
                      )}
                    </InputMask>
                  )}
                />
              </div>
              <p className="text">
              By clicking “Next Step” you consent to receive information and marketing e-mail messages, notifications  SMS/text messages and autodialed or prerecorded calls from us, lenders and our <Link target="_blank" to="/partners">marketing partners</Link> on the telephone number provided in the form (even if your number can be found in Do-Not-Call-Lists of any kind). In case you do not wish to receive any marketing messages, you can opt out at anytime by texting “STOP” to 3103400791. This consent is not required to obtain a loan.
              </p>
              <Box
                sx={{
                  maxWidth: "474px",
                  margin: "0 auto"
                }}
              >
                <StepBtn
                  prevLink={prevLink}
                  onSubmit={handleSubmit(onSubmit)}
                  loading={loadingButton}
                />
              </Box>
            </form>
            <StepPreFooter />
          </div>
        </Box>
        {/* <Footer desc={false} /> */}
      </Box>
    </>
  );
};

export default Cellphone;
