import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
// import Footer from "../../../components/Footer/Footer";

import "./Dob.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import { StepPreFooter } from "../../../components/Footer/StepPreFooter";

import { Controller, useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import dayjs from "dayjs";
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select
} from "@mui/material";

import dobIcon from "../../../assets/images/step-icon/dob-icon.png";

import { monthOptions } from "./options";
import {
  MenuProps,
  formControlCustom,
  labelCustom,
  menuItemCustom,
  selectCustom
} from "./style";

import { checkAge } from "../../../utils/validationUtils";
import { trimWhiteSpaceObjValue, getPrevStepLink } from "../../../utils/functions";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { nextStepDelayTime } from "../../../utils/const";

const Dob = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);

  const [openMonthOptions, setOpenMonthOptions] = useState(false);
  const [openDayOptions, setOpenDayOptions] = useState(false);
  const [openYearOptions, setOpenYearOptions] = useState(false);

  const [dob, setDob] = useState({
    month: contactRedux?.dob
      ? dayjs(contactRedux.dob, "YYYY-MM-DD").format("MM")
      : "",
    day: contactRedux?.dob
      ? dayjs(contactRedux.dob, "YYYY-MM-DD").format("DD")
      : "",
    year: contactRedux?.dob
      ? dayjs(contactRedux.dob, "YYYY-MM-DD").format("YYYY")
      : "",
    totalDayInMonth: 0
  });

  const currentLoc = useLocation();
  const prevLink = getPrevStepLink(currentLoc.pathname);

  useEffect(() => {
    // CHECK TOTAL DAY IN MONTH
    if (dob.month) {
      const isValidMonth = dayjs(dob.month, "MM").isValid();
      if (isValidMonth) {
        const totalDayInMonth = dayjs(dob.month, "MM").daysInMonth();
        setDob((prev) => ({ ...prev, totalDayInMonth }));
      }
    }

    // CHECK AGE
    if (dob.year) {
      const isValidYear = dayjs(`${dob.year}`, "YYYY").isValid();
      if (isValidYear) {
        const age = checkAge(dob.year);
        age < 18
          ? setError("age", {
              type: "manual",
              message: "You are not yet 18 years old"
            })
          : clearErrors("age");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dob.month, dob.year]);

  // SCROLL IN MOBILE
  useEffect(() => {
    if (windowWidth < 768) {
      window.scrollTo(0, 66);
    }
  }, [windowWidth]);

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  // HANDLE SUBMIT FORM
  const handleFormSubmit = async (data) => {
    if (loadingButton) {return}
    setLoadingButton(true);

    try {
      const convertedDate = `${data.month}-${data.day}-${data.year}`;
      data.dob = convertedDate;

      // DELETE WHITE SPACE FROM OBJECT VALUE
      trimWhiteSpaceObjValue(data);

      const lead_id = sessionStorage.getItem("lead_id");
      const res = await updateContact(dispatch, contactRedux?._id, {
        step: 5,
        lead_id,
        dob: `${data.year}-${data.month}-${data.day}`
      });

      const email = contactRedux?.email;
      if (email && res?.status === 200) {
        // sendDataToAllServices(email, firstName, lastName);

        setTimeout(() => {
          setLoadingButton(false);
          navigate("/address");
        }, nextStepDelayTime);
      }
    } catch (error) {
      console.error("Error updating contact:", error);
      setLoadingButton(false);
    }
  };
  const onSubmit = handleSubmit(handleFormSubmit);

  return (
    <Box className="step-2">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={4} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={dobIcon}
                alt=""
              />
            </Box>
            <h2 className="title">Date of Birth</h2>
            <div className="form-control">
              {/* date */}
              <FormHelperText sx={labelCustom}>Date of Birth</FormHelperText>
              <Box
                sx={{
                  display: "flex",
                  gap: "0",
                  marginBottom: "20px"
                }}
              >
                {/* MONTH */}
                <Controller
                  name="month"
                  defaultValue={dob.month}
                  control={control}
                  rules={{ required: "Month is required!" }}
                  render={({ field: { ref, ...field } }) => (
                    <FormControl
                      error={!!errors?.month}
                      size={isMobile ? "small" : "medium"}
                      sx={{ ...formControlCustom }}
                      fullWidth
                    >
                      <Select
                        {...field}
                        inputRef={ref}
                        defaultValue=""
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(value);
                          setDob((prev) => ({
                            ...prev,
                            month: value
                          }));
                        }}
                        value={dob.month}
                        displayEmpty
                        MenuProps={MenuProps}
                        sx={{
                          ...selectCustom,
                          borderRadius: openMonthOptions
                            ? "8px 0 0 0"
                            : "8px 0 0 8px",
                          "& fieldset": {
                            borderRight: "none"
                          },
                          "& .MuiSelect-select": {
                            color: !dob.month && "#B7B7B7"
                          }
                        }}
                        onClose={() => {
                          setOpenMonthOptions(false);
                        }}
                        onOpen={() => {
                          setOpenMonthOptions(true);
                        }}
                        className={openMonthOptions ? "show" : ""}
                        renderValue={(selected) => {
                          return !selected ? "MM" : selected;
                        }}
                      >
                        {monthOptions.map((option, index) => (
                          <MenuItem
                            key={index}
                            value={option.value}
                            sx={menuItemCustom}
                            size="small"
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors?.month && errors?.month.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
                {/* END MONTH */}
                {/* DAY */}
                <Controller
                  name="day"
                  defaultValue={dob.day}
                  control={control}
                  rules={{ required: "Day is required!" }}
                  render={({ field }) => (
                    <FormControl
                      error={!!errors?.day}
                      size={isMobile ? "small" : "medium"}
                      sx={formControlCustom}
                      fullWidth
                      disabled={!dob.month}
                    >
                      <Select
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(value);
                          setDob((prev) => ({
                            ...prev,
                            day: value
                          }));
                        }}
                        value={dob.day}
                        displayEmpty
                        defaultValue=""
                        MenuProps={MenuProps}
                        sx={{
                          ...selectCustom,
                          borderRadius: 0
                        }}
                        onClose={() => {
                          setOpenDayOptions(false);
                        }}
                        onOpen={() => {
                          setOpenDayOptions(true);
                        }}
                        className={openDayOptions ? "show" : ""}
                        native={false}
                        renderValue={(selected) => {
                          return !selected ? "DD" : selected;
                        }}
                      >
                        {[...Array(Number(dob.totalDayInMonth)).keys()].map(
                          (number) => {
                            const dayValue = number + 1;
                            const formattedDay =
                              dayValue < 10
                                ? "0" + dayValue
                                : dayValue.toString();
                            return (
                              <MenuItem
                                key={number}
                                value={formattedDay}
                                sx={menuItemCustom}
                                size="small"
                              >
                                {formattedDay}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                      <FormHelperText>
                        {errors?.day && errors?.day.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
                {/* END DAY */}
                {/* YEAR */}
                <Controller
                  name="year"
                  defaultValue={dob.year}
                  control={control}
                  rules={{ required: "Year is required!" }}
                  render={({ field }) => (
                    <FormControl
                      error={!!errors?.year}
                      size={isMobile ? "small" : "medium"}
                      sx={formControlCustom}
                      fullWidth
                      disabled={!dob.day}
                    >
                      <Select
                        {...field}
                        defaultValue=""
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(value);
                          setDob((prev) => ({
                            ...prev,
                            year: value
                          }));
                        }}
                        value={dob.year}
                        displayEmpty
                        MenuProps={MenuProps}
                        sx={{
                          ...selectCustom,
                          borderRadius: openYearOptions
                            ? "0 8px 0 0"
                            : "0 8px 8px 0",
                          "& fieldset": {
                            borderLeft: "none"
                          }
                        }}
                        onClose={() => {
                          setOpenYearOptions(false);
                        }}
                        onOpen={() => {
                          setOpenYearOptions(true);
                        }}
                        className={openMonthOptions ? "show" : ""}
                        renderValue={(selected) => {
                          return !selected ? "YYYY" : selected;
                        }}
                      >
                        {[...Array(100).keys()].map((number) => (
                          <MenuItem
                            key={number}
                            value={2023 - number}
                            sx={menuItemCustom}
                            size="small"
                          >
                            {2023 - number}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors?.day && errors?.day.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
                {/* END YEAR */}
              </Box>
              {errors?.age && (
                <FormHelperText
                  error
                  style={{
                    marginTop: "-15px",
                    marginBottom: "5px"
                  }}
                >
                  {errors?.age && errors?.age.message}
                </FormHelperText>
              )}
              {/* date */}
              <Box
                sx={{
                  maxWidth: "474px",
                  margin: "0 auto"
                }}
              >
                <StepBtn
                  prevLink={prevLink}
                  onSubmit={handleSubmit(onSubmit)}
                  loading={loadingButton}
                />
              </Box>
            </div>
          </form>
          <StepPreFooter />
        </div>
      </Box>
      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default Dob;
